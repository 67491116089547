<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-funcionario v-if="!view" class="mr-2"></select-funcionario>
          <v-text-field
            v-else
            class="mr-2"
            label="Funcionário"
            name="funcionario"
            placeholder="Funcionário"
            v-model="funcionario"
            :readonly="true"
          ></v-text-field>
          <select-praca v-if="!view" :cod-funcionario="idFuncionario" class="mr-2"></select-praca>
          <v-text-field
            v-else
            class="mr-2"
            label="Praça"
            name="praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-menu
            v-if="!view"
            class="pr-2"
            ref="bornDate"
            lazy
            :close-on-content-click="false"
            v-model="dataSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataSaidaRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Data saída"
              v-model="dataSaidaRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataSaidaRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.bornDate.save(dataSaidaRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-else
            class="mr-2"
            label="Data saída"
            name="dataSaida"
            placeholder="Data saída"
            v-model="dataSaidaRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-menu
            v-if="!view"
            class="pr-2"
            ref="retornoDate"
            lazy
            :close-on-content-click="false"
            v-model="dataRetorno"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataRetornoRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Data retorno"
              v-model="dataRetornoRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataRetornoRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataRetorno = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.retornoDate.save(dataRetornoRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-else
            class="mr-2"
            label="Data retorno"
            name="dataRetorno"
            placeholder="Data retorno"
            v-model="dataRetornoRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-switch
            :readonly="true"
            v-model="switch1"
            :label="`Status: ${switch1 == true ? 'FINALIZADO' : 'INICIADO'}`"
          >
          </v-switch>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidadeDevolvida"
                  required
                ></v-text-field>
              </td>
            </template>
          </v-data-table>
        </div>
        <div class="form-btn">
          <v-btn @click="voltar" outline>Voltar</v-btn>
          <v-btn outline @click="submit" color="primary">Salvar</v-btn>
        </div>
        <div>
          <progress-circular></progress-circular>
        </div>
        <v-snackbar :color="'error'" v-model="snackbar" :top="true">
          {{ text }}
          <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
import ProgressCircular from "../progressCircular/progressCircular"
import RomaneioConferenciaProduto from "../../domain/romaneioConferencia/RomaneioConferenciaProduto"
import RomaneioConferencia from "../../domain/romaneioConferencia/RomaneioConferencia"
import SelectFuncionario from "../funcionario/selectFuncionario.vue"
import SelectPraca from "../praca/selectPracaPorFuncionario.vue"
const servicoRomaneio = new ServicoRomaneio()
export default {
  components: {
    SelectFuncionario,
    SelectPraca,
    ProgressCircular
  },
  data() {
    return {
      dialog: false,
      id: null,
      valid: false,
      idFuncionario: null,
      funcionario: "",
      praca: "",
      idPraca: null,
      dataSaida: false,
      dataSaidaRomaneio: null,
      dataRetorno: null,
      dataRetornoRomaneio: null,
      produtos: [],
      produtoModal: null,
      errosValidacoes: false,
      mensagemErro: null,
      switch1: true,
      view: false,
      snackbar: false,
      text: "",
      headers: [
        { text: "Referência", align: "left", sortable: false, value: "quantidade" },
        { text: "Nome", align: "left", sortable: false, value: "referenciaInformada" },
        { text: "Quantidade retorno", align: "left", sortable: false }
      ],
      headersSacola: [{ text: "Código de barras", align: "left", sortable: false, value: "item" }]
    }
  },
  created() {
    serverBus.$on("funcionarioSelected", value => {
      this.idFuncionario = value
    })
    serverBus.$on("pracaSelected", value => {
      this.idPraca = value
    })
    serverBus.$on("produtosSelecionados", value => {
      this.produtos = value
    })
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoRomaneio.buscarPorId(this.id).then(res => {
        this.view = true
        this.id = res.data.id
        this.idFuncionario = res.data.idFuncionario
        this.funcionario = res.data.funcionario
        this.praca = res.data.praca
        this.idPraca = res.data.idPraca
        this.dataSaidaRomaneio = res.data.dataSaidaString
        this.dataRetornoRomaneio = res.data.dataRetornoString
        this.produtos = res.data.produtos
        this.switch1 = res.data.finalizado

        serverBus.$emit("produtoSelected", res.data.produtos)
      })
    }
  },
  methods: {
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        let romaneio = new RomaneioConferencia()
        romaneio.Id = parseInt(this.id)
        romaneio.Produtos = []
        this.produtos.forEach(element => {
          var produto = new RomaneioConferenciaProduto()
          produto.id = element.id != undefined ? parseInt(element.id) : 0
          produto.idRomaneio = this.id != undefined ? parseInt(this.id) : 0
          produto.codProduto = parseInt(element.idProduto)
          produto.quantidadeDevolvida = parseInt(element.quantidadeDevolvida)
          romaneio.Produtos.push(produto)
        })
        servicoRomaneio.salvarConferencia(romaneio).then(
          res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.$router.push({ name: "romaneio-conferencia" })
            } else {
              this.fecharProgressCircular()
              this.dialog = true
              this.errosValidacoes = true
              this.mensagemErro = res.data[0].title + " - " + res.data[0].detail
            }
          },
          err => {
            this.fecharProgressCircular()
            // eslint-disable-next-line
            console.log(err)
          }
        )
      } else {
        this.fecharProgressCircular()
      }
    },
    abrirModal(produto) {
      this.produtoModal = produto
      this.dialog = true
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "romaneio-conferencia" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
